/* eslint-env browser */
import React from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@mui/system/styled";

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import {PageProvider} from "./contexts/PageContext.jsx";
import {SearchProvider} from "./contexts/SearchContext.jsx";
import useJwt from "./contexts/useJwt.jsx";
import useEnvironmentVariables from "./hooks/useEnvironmentVariables.jsx";
import DisabledMessage from "./primitives/DisabledMessage.jsx";
import FacilityNotification from "./primitives/FacilityNotification.jsx";
import FloatingActionBuffer from "./primitives/FloatingActionBuffer.jsx";
import TableLoading from "./primitives/TableLoading.jsx";
import AutoLogout from "./AutoLogout.jsx";
import BuildNotifier from "./BuildNotifier.jsx";
import SideBar from "./SideBar.jsx";
import TopBar from "./TopBar.jsx";

const DRAWER_WIDTH = 240;

//---------------------------------------------------------------------------
// Styled component that contains the main content of the page below the sidebar,
// acts as a wrapper for the content that gets slid to the right
// when the side bar is opened
//---------------------------------------------------------------------------
const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})(({theme, open, mobile}) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "100%",
  ...(!mobile && {
    padding: theme.spacing(3),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${DRAWER_WIDTH}px`,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    }),
  }),
}));

function Layout() {
  //---------------------------------------------------------------------------
  // Global variables
  //---------------------------------------------------------------------------
  const {refreshJwtThresholdSeconds} = useEnvironmentVariables();

  // Convert true/false to 1/0 to avoid console errors
  const mobile = useMediaQuery("(max-width:899px)") ? 1 : 0;

  const [open, setOpen] = React.useState(!mobile);

  const handleClick = React.useCallback(() => setOpen((prev) => !prev), []);
  const handleCloseMobile = React.useCallback(() => {
    if (mobile) {
      setOpen(false);
    }
  }, [mobile]);

  const {userId} = useJwt();

  const {pathname} = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!userId) {
      navigate("/login", {state: {from: pathname, message: "You were logged out from another tab."}});
    }
  }, [navigate, pathname, userId]);

  if (!userId) {
    return null;
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Box
      sx={{
        "@page": {
          size: "330mm 427mm",
          my: 10,
        },
        "@media print": {
          width: "1204px",
          colorAdjust: "exact" /* Firefox. This is an older version of "print-color-adjust" */,
          printColorAdjust: "exact" /* Firefox/Safari */,
          WebkitPrintColorAdjust: "exact" /* Chrome/Safari/Edge/Opera */,
        },
      }}
    >
      <SearchProvider>
        <PageProvider>
          <BuildNotifier intervalSeconds={15} />

          <DisabledMessage />
          <FacilityNotification />

          <AutoLogout throttle={refreshJwtThresholdSeconds * 1000} warningSeconds={60 * 2} />
          <TopBar onClick={handleClick} onClose={handleCloseMobile} />
          <Drawer
            sx={{"@media print": {display: "none"}}}
            PaperProps={{
              sx: {width: DRAWER_WIDTH, boxSizing: "border-box"},
            }}
            variant={mobile ? "temporary" : "persistent"}
            ModalProps={{keepMounted: true}}
            anchor="left"
            open={open}
            onClose={handleCloseMobile}
          >
            <Toolbar sx={{"@media print": {display: "none"}}} />
            <SideBar onClick={handleCloseMobile} />
          </Drawer>
          <Toolbar />
          <Main open={open} mobile={mobile}>
            <Container maxWidth="xl">
              <React.Suspense fallback={<TableLoading />}>
                <Outlet />
              </React.Suspense>
            </Container>
            <FloatingActionBuffer />
          </Main>
        </PageProvider>
      </SearchProvider>
    </Box>
  );
}

export default Layout;
